.App {
    text-align: center;
}

:root {
    --white: #fff;
    --light-blue: lightblue;
    --color-blue: blue;
    --color-dark-grey: #222831;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 2s linear infinite;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.text-negative {
    background: rgba(216, 47, 68, 0.1);
}

.text-positive {
    background: rgba(25, 175, 85, 0.1);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* .available-width {
    width: -webkit-fill-available;
    width: -moz-available;
    transition: 'width 0.3s';
} */

.apexcharts-bar-area {
    cursor: pointer;
}

.text-negetive {
    background: rgba(216, 47, 68, 0.1);
}

.text-positive {
    background: rgba(25, 175, 85, 0.1);
}

.text-forward {
    background: rgba(107, 34, 227, 0.2);
}

.typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
    animation-delay: 0s;
}

.typing__dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}

.load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
}

.collapsed {
    overflow: hidden;
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    -webkit-box-pack: center;
}

.clamp-line-1 {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    -webkit-box-pack: center;
}

.clamp-line-2 {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-pack: center;
}

.clamp-line-3 {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    -webkit-box-pack: center;
}

.clamp-line-4 {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    -webkit-box-pack: center;
}

.beamer_icon {
    display: none !important;
}

#beamerSelector {
    display: none !important;
}

.spinner {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
}
.spinner .ball {
    width: 20px;
    height: 20px;
    background-color: #6e3cfb;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.loader-text {
    color: #6e3cfb;
    margin-top: 5px;
    letter-spacing: 3px;
    font-size: 10px;
}

@-webkit-keyframes motion {
    0% {
        transform: translateX(0) scale(1);
    }
    25% {
        transform: translateX(-50px) scale(0.3);
    }
    50% {
        transform: translateX(0) scale(1);
    }
    75% {
        transform: translateX(50px) scale(0.3);
    }
    100% {
        transform: translateX(0) scale(1);
    }
}

@keyframes motion {
    0% {
        transform: translateX(0) scale(1);
    }
    25% {
        transform: translateX(-50px) scale(0.3);
    }
    50% {
        transform: translateX(0) scale(1);
    }
    75% {
        transform: translateX(50px) scale(0.3);
    }
    100% {
        transform: translateX(0) scale(1);
    }
}

.max-window-available {
    max-height: 100vh;
    overflow: auto;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes myfirst {
    0% {
        top: 16px;
    }
    50% {
        top: 96px;
    }
    100% {
        top: 16px;
    }
}

/* Standard syntax */
@keyframes myfirst {
    0% {
        top: 16px;
    }
    50% {
        top: 96px;
    }
    100% {
        top: 16px;
    }
}

.apexcharts-marker {
    cursor: pointer;
}

.pdf-summary .hide-export {
    display: none;
}

summary {
    font-size: 1rem;
    font-weight: 600;
    background-color: #05051e;
    color: #f3ab2d;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    margin-bottom: -10px;
    transition: all 0.5s ease;
}

details[open] summary {
    margin-bottom: 0px;
} /* Add a sliding animation when clicked */

.wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.detail-wrapper {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
}
details {
    width: 50%;
}

.wrapper > .detail-wrapper:nth-child(even) {
    margin-left: 50%;
    padding-left: 15px;
}
.wrapper > .detail-wrapper:not(:nth-child(even)) {
    margin-left: -15px !important;
}

.notistack-SnackbarContainer {
    z-index: 999999 !important;
}

.detail-wrapper::before {
    content: '';
    width: 4px;
    background-color: #e59413;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
}

.detail-wrapper::after {
    font-family: Font Awesome\5 Free;
    content: '\f058';
    text-align: center;
    font-size: 40px;
    color: #f3ab2d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    left: 50%;
    background: white;
    z-index: 999;
    margin-left: -25px;
    border-radius: 50%;
}

@media only screen and (max-width: 787px) {
    .wrapper {
        width: 80vw;
    }
}

.citation-cta {
    margin-left: 2px;
    position: relative;
    font-size: 10px;
    width: 15px;
    text-align: center;
    height: 15px;
    line-height: 13px;
    display: inline-block;
    top: -4px;
    border: 1px solid #a5a6ab;
    background-color: #a5a6ab;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;
}
.citation-cta:hover {
    border: 1px solid #6e3cfb;
    background-color: #6e3cfb;
}

.editorClassName {
    min-height: 6em;
}

.HW_frame_cont {
    left: 89px !important;
}

.HW_badge_cont {
    height: 0px !important;
    width: 0px !important;
}

#HW_badge {
    display: none;
}

.markdown table {
    border-collapse: collapse;
}
.markdown th,
.markdown td {
    padding: 6px 13px;
    text-align: left !important;
    border: 1px solid black;
}
.markdown p {
    font-size: 16px;
    margin: 12px 0px;
}
.markdown li {
    font-size: 16px;
    margin: 4px 0px;
}
.markdown td {
    font-size: 16px;
    margin: 4px 0px;
}

.markdown a {
    border-bottom: 2px dotted #000;
    text-decoration: none;
    color: #000;
}

.markdown a:hover {
    text-decoration: none;
    border-bottom: 2px dotted #6e3cfb;
    color: #6e3cfb;
}

.loading {
    display: inline-block;
    clip-path: inset(0 2ch 0 0);
    animation: l 1s steps(6) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0);
    }
}

::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-button {
    display: none;
}

::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
    width: 0px;
}

::-moz-scrollbar-button,
::-webkit-scrollbar-button {
    width: 0px;
}

::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}
